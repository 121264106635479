'use strict';

(function($){
  var app = {

    init: function() {
      this.slick();
      this.cookiesAccept();
      this.cookiesConfig();
      this.lazyframe();
      this.buscador();
      this.menuLang();
      return false;
    },
    slick: function() {

      $('.home-carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: '<button class="slide-arrow slick-prev"></button>',
        nextArrow: '<button class="slide-arrow slick-next"></button>'
      });

      $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        prevArrow: '<button class="slide-arrow slick-prev"></button>',
        nextArrow: '<button class="slide-arrow slick-next"></button>',
        asNavFor: '.slider-nav'
      });

      $('.slider-nav').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        focusOnSelect: true
      });
      $('.slider-soluciones').slick({
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots:true,
        centerMode: true,
        responsive: [{
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        }, {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            infinite: true,
    
          }
        },  {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
          }
        }]
      });
	},

    cookiesAccept: function () {
      var msgCookies = $('#cookies-msg');
      var btnAccept = $('.btn-cookies-accept');
      btnAccept.click(function (e){
        e.preventDefault();
        $.cookie(keyAnalyticCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyMarketingCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyRrssCookies, '1', { expires: 7, path: '/' });
        msgCookies.slideUp();
      })
    },
    cookiesConfig: function (){
      var configContainer = $('.cookies-configuration-block');
      var msgCookies = $('#cookies-msg');

      if(typeof configContainer.html() !== 'undefined')
      {
        var checkAll = configContainer.find('input#cookies-todas');
        var checkAnalytics = configContainer.find('input#cookies-analiticas');
        var checkMarketing = configContainer.find('input#cookies-marketing');
        var checkRrss = configContainer.find('input#cookies-rrss');
        var buttonSave = configContainer.find('.btn-save-config');
        var msgSuccess = configContainer.find('#text-success');
        var msgError = configContainer.find('#text-error');

        checkAll.click(function ()
        {
          if($(this).prop('checked'))
          {
            checkAnalytics.prop('checked',true);
            checkMarketing.prop('checked',true);
            checkRrss.prop('checked',true);
          }
          else
          {
            checkAnalytics.prop('checked',false);
            checkMarketing.prop('checked',false);
            checkRrss.prop('checked',false);
          }
        })
        buttonSave.click(function ()
        {
          msgSuccess.addClass('d-none');
          msgError.addClass('d-none');
          $.cookie(keyAnalyticCookies, ((checkAnalytics.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyMarketingCookies, ((checkMarketing.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyRrssCookies, ((checkRrss.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          if(typeof msgCookies.html() !== 'undefined') msgCookies.hide();
          msgSuccess.removeClass('d-none');
        })
      }
    },
    lazyframe: function () {
      lazyframe('.lazyframe', {
        apikey: 'AIzaSyBG22hzTpV5lxNFeYgNDuv1SSD_NBEAu9k', // cambiar esta API por la del proyecto https://github.com/vb/lazyframe#apikey
        debounce: 250,

        /*onAppend: (function (lazyframe) {
          return console.log(lazyframe.parentNode.classList.add("loaded"))
        }) */
      });
      //$("img.lazy").lazyload();
   
    },
    buscador: function () {
      function buttonUp(){
        var valux = $('.sb-search-input').val(); 
           valux = $.trim(valux).length;
           if(valux !== 0){
               $('.sb-search-submit').css('z-index','99');
           } else{
               $('.sb-search-input').val(''); 
               $('.sb-search-submit').css('z-index','-999');
           }
        }
    },
    menuLang : function () {
        let selectLanguages = $('.select-language');
        selectLanguages.change(function () {
            window.location.href = $(this).val();
        })
    }
  };
  $(function(){
    if (window.matchMedia("(min-width: 1300)").matches) {
      /* La pantalla tiene al menos 1300 píxeles de ancho */
      $('.navbar-light .dmenu').hover(function () {
              $(this).find('.sm-menu').first().stop(true, true).slideDown(150);
          }, function () {
              $(this).find('.sm-menu').first().stop(true, true).slideUp(105)
      });
    } else {
      /* La pantalla tiene menos de 1300 píxeles de ancho */
      $('.navbar-light .dmenu').click(function () {
              $(this).find('.sm-menu').first().stop(true, true).slideDown(150);
          }, function () {
              $(this).find('.sm-menu').first().stop(true, true).slideUp(105)
      });
    }
    }); 
    $(document).ready(function() {

    // Mega menu
    $(".megamenu").on("click", function(e) {
      e.stopPropagation();
    });

    // Buscador
    var submitIcon = $('.sb-icon-search');
        var submitInput = $('.sb-search-input');
        var searchBox = $('.sb-search');
        var isOpen = false;
        $(document).mouseup(function(){
            if(isOpen == true){
            submitInput.val('');
            $('.sb-search-submit').css('z-index','-999');
            submitIcon.click();
            }
        });
        submitIcon.mouseup(function(){
            return false;
        });
        searchBox.mouseup(function(){
            return false;
        });  
        submitIcon.click(function(){
            if(isOpen == false){
                searchBox.addClass('sb-search-open');
                isOpen = true;
            } else {
                searchBox.removeClass('sb-search-open');
                isOpen = false;
            }
        });

      app.init()
    });
})(jQuery);